@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-page {
  background: rgba(1, 91, 67, 0.959);
  background: linear-gradient(
    5deg,
    rgba(21, 91, 67, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
